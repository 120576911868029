<template>
  <span>
      <a href="#" class="ms-participant" @click='show = true'> 
      <span v-if="creator">
        <i v-if="getStatus == 0" class="fal fa-envelope"></i>
        <i v-if="getStatus == 1" class="fal fa-envelope-open"></i>
        <i v-if="getStatus == 2" class="fal fa-ban"></i>
        <i v-if="getStatus == 3" class="fal fa-comment"></i>
        <i v-if="getStatus == 10" class="fal fa-exclamation-circle red"></i>
      </span>
      {{info.email}}<span v-if="!islast">; </span></a>

       <b-sidebar
      id="message-participant"
      v-model="show"
      width="80%"
      right
      no-slide
      shadow
      backdrop
    >
      <div class="px-3 py-2">

        <UserInfo
            class="mt-4"
            image="/img/default-avatar.png"
            :primary="info.email"
          ></UserInfo>

          
          <hr class="pb-0 mb-0 mt-2" />

        <dl class="clearfix mt-2">
            <dt>{{ $t("STATUS") }}</dt>
            <dd>
              <i v-if="getStatus == 0" class="fal fa-envelope"></i>
              <i v-if="getStatus == 1" class="fal fa-envelope-open"></i>
              <i v-if="getStatus == 2" class="fal fa-ban"></i>
              <i v-if="getStatus == 3" class="fal fa-comment"></i>
              <i v-if="getStatus == 10" class="fal fa-exclamation-circle red"></i>
              {{ showStatus }}
            </dd>
            <dt v-if="info.fetched">{{ $t('VIEWED') }}</dt>
            <dd v-if="info.fetched">{{ $luxonDateTime.fromISO(info.fetched_date).toLocaleString($luxonDateTime.DATETIME_MED) }}</dd>
            <dt v-if="info.recalled">{{ $t('RECALLED') }}</dt>
            <dd v-if="info.recalled">{{ $luxonDateTime.fromISO(info.recalled_date).toLocaleString($luxonDateTime.DATETIME_MED) }}</dd>
            <dt v-if="responsed">{{ $t('RESPONSED') }}</dt>
            <dd v-if="responsed">{{ $luxonDateTime.fromISO(info.response_date).toLocaleString($luxonDateTime.DATETIME_MED) }}</dd>
        </dl>

        <RespondedMessage class="mt-2" v-if="showResponse" :message_uuid="info.response_message_uuid"></RespondedMessage>
   
        <hr class="pb-0 mb-0 mt-2" />

        <b-button v-if="info.response_message_uuid != '' && showResponse == false"
          variant="primary"
          size="sm"
          class="btn-fill mt-2"
          @click="viewResponse"
          >{{ $t("SHOW_RESPONSE") }}</b-button
        >

        <b-button v-if="getStatus == 0"
          variant="primary"
          size="sm"
          class="btn-fill mt-2"
          @click="recall"
          >{{ $t("RECALL") }}</b-button
        >
          <b-button
            v-if="getStatus == 10"
            variant="primary"
            size="sm"
            class="btn-fill mt-2"
            @click="resend"
            >{{ $t("RESEND") }}</b-button
          >

      </div>
    </b-sidebar>
              
  </span>
</template>
<script>
export default {
  components: { 
    RespondedMessage: () => import("@/components/Read/RespondedMessage"),
    UserInfo: () => import("@/components/Layout/UserInfo")
  },
  props: ["response","item","islast","creator","status"],
  data() {
    return {
      show: false,
      info: this.item,
      showDialog: false,
      viewed: false,
      viewed_date: "",
      showResponse: false
    };
  },
  methods: {    
    viewResponse: function() {
      this.showResponse = true;
    },
    onShow: function() {
      this.$root.$emit("bv::hide::popover");
    },
    onClose: function() {
      this.showDialog = false;
    },   
    recall: function() {
      let self = this;
      this.loading = true;
      
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.$http
              .post(self.user.hostname + "/message/user/recall", {
                  participant_id: self.info.id
                })
              .then(
                function(response) {
                  self.info.recalled = 1;
                  self.info.recalled_date = response.data.recalled_date;
                  self.$noty.info(self.$t("UPDATED"));
                }
              )
              .catch(function(error) {                
                self.loading = false;
                if(error.toString() == "Error: fail_4_4") return;
                if (error.response != undefined) {
                  self.$noty.error(self.$t(error.response.data));
                }
              });
        }
      })
      .catch(function(){
      });      
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/resend",
              {
                participant_id: self.info.id,
              }
            )
            .then(function() {
              self.loading = false;
              if(self.info.sent != undefined)
              {
                self.info.sent = 1;
              }
              self.$noty.info(self.$t("UPDATED"));
            })
            .catch(function(error) {
              self.loading = false;
              if(error.toString() == "Error: fail_4_4") return;
              if (error.response != undefined) {
                self.$noty.error(self.$t(error.response.data));
              }
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: { 
    responsed () {
      return this.info.response_message_uuid != "";
    }, 
    getStatus() {
      if(this.responsed)
      {
        return 3;
      }
      if(this.info.recalled == 1)
      {
        return 2;
      }
      if(this.info.fetched == 1)
      {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    showStatus() {
      if(this.responsed)
      {
        return this.$t("RESPONSED");
      }
      if(this.info.recalled == 1)
      {
        return this.$t("RECALLED");
      }
      if(this.info.fetched == 1)
      {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
    }
  },
  mounted() {
    if(this.creator)
    {
      for (var status of this.status) {
        if(status.email == this.info.email)
        {
          this.fetched = status.fetched;
          this.fetched_date = status.fetched_date;
        }
      }
    }    
  }
};
</script>
<style>
</style>
